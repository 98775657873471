// About.js
import React from 'react';
import '../App.css';
import Layout from "../../components/layout";

const History = () => {
    return (
        <Layout>
            <h1>History of the TOGKA</h1>
            <p>In 1989 after Sensei Ravey's departure from the IOGKF, he established the Traditional Okinawan
                Goju Ryu
                Karate-do Association (TOGKA) with a purpose to preserve the pure form of traditional Goju Ryu
                without
                politics.</p>
            <h2>In Australia:</h2>
            <p>Sensei Ravey became Chief Instructor of the newly formed association along with, Rob Williams, a
                friend
                and student who had trained with Sensei Ravey for about 5 years, who became the TOGKA treasurer,
                Terry
                Lyon, in Perth Australia, who was also IOGKF, became the first TOGKA 3rd Dan, Michael Leham, a
                BJC Zen
                Do Kai black belt from the Gold Coast, Australia, who came to train a few "heavy sessions", also
                joined
                the TOGKA, and Don Weir, who had spent several years training in Japan at Yoyogi Dojo with
                Sensei
                Ravey.</p>
            <h2>In France:</h2>
            <p>Sensei Gavin Hall (formally of Sheffield, England) became the representative of France.</p>
            <h2>In England:</h2>
            <p>Sensei Kenny Allen (from Chesterfield) became the representative of England. The TOGKA continued
                strongly
                in Australia, England and France for several years, before welcoming the addition of dojos in
                Tanzania
                and India who wanted to join the non-profit organisaton.</p>

        </Layout>
    );
}

export default History;
